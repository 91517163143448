.formBox[data-v-cc1b84f4] {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.formTitleBox[data-v-cc1b84f4] {
  width: calc(100% - 24px);
  border-bottom: 1px solid #bbbbbb;
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.leftBack[data-v-cc1b84f4] {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.backBox[data-v-cc1b84f4] {
  position: relative;
  line-height: 28px;
}
.backBox[data-v-cc1b84f4]:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 16px;
  right: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #dcdfe6;
}
.rightBtn[data-v-cc1b84f4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.formContentBox[data-v-cc1b84f4] {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain[data-v-cc1b84f4] {
  width: 100%;
  overflow: auto;
  height: 100%;
}
.leftClass[data-v-cc1b84f4] {
  width: calc(15% - 48px);
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  padding: 12px 24px;
}
.classDataBox[data-v-cc1b84f4] {
  height: calc(100% - 34px);
  overflow: auto;
}
.classLittle[data-v-cc1b84f4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: calc(100% - 14px);
  border: 1px solid #bbbbbb;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 4px;
  margin: 12px 0;
  cursor: pointer;
  font-size: 15px;
  padding: 0 6px;
}
.rightTable[data-v-cc1b84f4] {
  width: 84%;
}
.classActive[data-v-cc1b84f4] {
  background-color: #E9EDF3;
}
.container[data-v-cc1b84f4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.formTopic[data-v-cc1b84f4] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-weight: 600 !important;
  font-size: 18px;
}